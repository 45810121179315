<template>
  <div class="horiz-box flex-start">
    <h1 class="title">
      My Projects
    </h1>
  </div>

  <a class="game fancy-box" href="https://lumatron.art">
    <div class="left">
      <img src="@/assets/lumatron-logo.png">
      <!-- <video controls autoplay loop muted>
        <source src="@/assets/sierpinskitron.mp4" type="video/mp4">
      </video> -->
      <video controls autoplay loop muted>
        <source src="@/assets/sierpinski.mp4" type="video/mp4">
      </video>
    </div>
    <div class="right">
      <div>
        Lumatron (formerly Super Orbitron) is an system where I make custom lighting art. Each Lumatron is guaranteed to be unique and I work with the recipient to incorporate their preferences. Developed with considerable help from Manadream.
      </div>
    </div>
  </a>

  <div class="horiz-box"></div>
  
  <a class="game fancy-box" href="https://store.steampowered.com/app/1937110/Card_Craft/">
    <div class="left">
      <img src="@/assets/tetra-tactics.png">
      <video controls autoplay loop muted>
        <source src="https://cdn.akamai.steamstatic.com/steam/apps/257013897/movie480_vp9.webm" type="video/webm">
      </video>
    </div>
    <div class="right">
      <div>
        Tetra Tactics is the brain-child of Jabari Alii and I joined on a few months in. Tetra Tactics is a deck-building roguelike with mechanics directly inspired by Triple Triad, the card minigame from Final Fantasy VIII.
      </div>
      <img class="icon" src="@/assets/steam_white.svg">
    </div>
  </a>

  <div class="horiz-box"></div>

  <a class="game fancy-box" href="https://docs.google.com/presentation/d/1Y_Mi2tBJTW3qrmUiV2SSuWBtBIAkzn4HM_j3WUY_2kE/edit?usp=sharing">
    <div class="left">
      <img src="@/assets/cornhole-rpg-sign.jpg">
      <img src="@/assets/cornhole-rpg-example.jpg">
<!--       <video controls autoplay loop muted>
        <source src="@/assets/coop-chess-game-over.mp4" type="video/mp4">
      </video> -->
    </div>
    <div class="right">
      <div>
        Cornhole RPG is like cornhole in that you toss a bag towards a board with a hole in it, only it's cooperative instead of competitive and more importantly, you throw beanie babies instead of standard cornhole bags. Sign up to my mailing list to get access to a print and play with more details.
      </div>
    </div>
  </a>

  <div class="horiz-box"></div>

  <a class="game fancy-box" href="https://store.steampowered.com/app/1585350/Cooperative_Chess/">
    <div class="left">
      <img src="@/assets/coop-chess.png">
      <video controls autoplay loop muted>
        <source src="@/assets/coop-chess-game-over.mp4" type="video/mp4">
      </video>
    </div>
    <div class="right">
      <div>
        Cooperative Chess is exactly what is sounds like. You and a friend play as black and white and your goal is simply to complete a valid game of chess, but obstacles are thrown in your way to make you lose instantly. You both have to coordinate without communicating to avoid losing and acheive checkmate.
      </div>
      <img class="icon" src="@/assets/steam_white.svg">
    </div>
  </a>

  <div class="horiz-box"></div>

  <a class="game fancy-box" href="https://store.steampowered.com/app/1676280/Metasweeper/">
    <div class="left">
      <img src="@/assets/Metasweeper.png">
      <video controls autoplay loop muted>
        <source src="@/assets/metasweeper-trailer.mp4" type="video/mp4">
      </video>
      <!-- <div class="video_wrapper">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/s5wJt0EslKw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div> -->
    </div>
    <div class="right">
      <div>
        Metasweeper is a minesweeper Zelda-like that covers the very basics of minesweeping, but then takes it in some amazing and unexpected directions. Metasweeper is currently on indefinite hiatus, but I keep thinking about it, so who knows ¯\_(ツ)_/¯.
      </div>
      <img class="icon" src="@/assets/steam_white.svg">
    </div>
  </a>

  <div class="horiz-box"></div>

  <a class="game fancy-box" href="https://store.steampowered.com/app/1102040/Obversion">
    <div class="left">
      <img src="@/assets/Obversion.png">
      <video controls autoplay loop muted>
        <source src="@/assets/obversion-trailer.mp4" type="video/mp4">
      </video>
      <!-- <div class="video_wrapper">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/cPBluRnIT6M" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div> -->
    </div>
    <div class="right">
      <div>
        Obversion was my first serious game. It's a challenging first-person puzzle game about creating and destroying cubes. With a heavy focus on removing the execution skill from the first-person experience, Obversion also has some first-class accessiblity features including infinite undo, autojump, and automove.
      </div>
      <img class="icon" src="@/assets/steam_white.svg">
    </div>
  </a>

  <!-- <div class="horiz-box flex-start">
    <h1 style="text-decoration: underline; font-family: Arial;">
      <a href="/press">Presskit</a>
    </h1>
  </div> -->

  <div class="horiz-box medium"></div>

  <Signup message="Sign up to hear about what I do with all of the above projects."/>
</template>

<script>
import Signup from "@/components/Signup"

export default {
  name: 'Games',
  components: { Signup },
}
</script>

<style>
.game {
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  font-size: 2.5em;
  font-weight: normal;
}


.game .left {
  width: 48%;
  padding: 2px 0;
}
.game .left > *:first-child {
  margin-bottom: 24px;
}

.game .right {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.game a {
  text-decoration: underline;
}

.game .icon {
  width: 40px;
  height: 40px;
}

.game img, .game video {
  padding: 0;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .game {
    flex-direction: column;
  }
  .game .left, .game .right {
    width: 100%;
  }
}
</style>
